<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ parData.PARCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
        <v-alert
          border="left"
          type="warning"
          v-if="renewalData"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div class="font-size-16">SUPPLY RENEWAL</div>
          {{renewalData.Description}} starts from {{DateStart_renewal}} to {{DateEnd_renewal}}.
        </v-alert>
          </div>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6 text-center"
                  >PROPERTY ACKNOWLEDGEMENT RECEIPT</v-subheader
                >
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="PARItemID"
                class="elevation-1 mt-4"
                :expanded.sync="expanded"
                :loading="isLoading"
                show-expand
                show-select
                @toggle-select-all="selectAllToggle"
                :items-per-page="-1"
                :footer-props="{
                  'items-per-page-options': [-1]
                }"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-2"
                      class="white--text mr-2"
                      :disabled="formData.Lists.length == 0"
                      @click="renew()"
                      v-if="renewalData"
                      small
                    >
                      <v-icon class="mr-1">mdi-autorenew</v-icon>
                      RENEW
                    </v-btn>
                    <v-btn
                      color="amber darken-2"
                      class="white--text mr-2"
                      :disabled="formData.Lists.length == 0"
                      @click="transfer()"
                      small
                    >
                      <v-icon class="mr-1">mdi-transfer</v-icon>
                      TRANSFER
                    </v-btn>
                    <v-btn
                      color="blue darken-2"
                      class="white--text"
                      :disabled="formData.Lists.length == 0"
                      @click="returnPAR()"
                      small
                    >
                      <v-icon class="mr-1">mdi-clipboard-arrow-left</v-icon>
                      RETURN
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    :readonly="item.disabled"
                    :disabled="item.disabled"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                    {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <!-- <template v-slot:[`item.DateAction`]="{ item }">
                  <span v-if="item.DateRenewed != null">{{formatDate(item.DateRenewed)}}</span>
                  <span v-else-if="item.DateTransferred != null">{{formatDate(item.DateTransferred)}}</span>
                  <span v-else>Not Applicable</span>
                </template> -->
                <template v-slot:[`item.RenewQty`]="{ item }">
                  {{ formatQty(item.RenewQty) }}
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="150"
                    offset-x
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.RenewQty > 0" icon absolute color="green darken-2" class="mt-n4" small>
                      <v-icon>mdi-help-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar color="header darken-2">
                          <v-icon large color="white">
                            mdi-autorenew
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="header--text darken-2 font-weight-bold">Renewed</v-list-item-title>
                          <v-list-item-subtitle>Number of PAR: <b>{{item.renewed_par.length}}</b></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                          <v-btn
                            icon
                            @click="menu = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item v-for="(renewed, index) in item.renewed_par" :key="index">
                        <v-list-item-title>{{renewed.PARCODE}}</v-list-item-title>
                        <v-list-item-action class="mr-1">
                          <span class="text-no-wrap">
                          <v-btn icon color="blue darken-2" small @click="viewPAR(renewed)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon color="red darken-2" small @click="printPAR(renewed)">
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                </template>
                <template v-slot:[`item.TransferQty`]="{ item }">
                  {{formatQty(item.TransferQty)}}
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="150"
                    offset-x
                  >
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" v-if="item.TransferQty > 0" icon absolute color="amber darken-2" class="mt-n4" small>
                      <v-icon>mdi-help-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar color="amber darken-2">
                          <v-icon large color="white">
                            mdi-transfer
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="amber--text darken-2 font-weight-bold">Transferred</v-list-item-title>
                          <v-list-item-subtitle>Number of PAR: <b>{{item.transferred_par.length}}</b></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                          <v-btn
                            icon
                            @click="menu = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item v-for="(transferred, index) in item.transferred_par" :key="index">
                        <v-list-item-title>{{transferred.PARCODE}}</v-list-item-title>
                        <v-list-item-action class="mr-1">
                          <span class="text-no-wrap">
                          <v-btn icon color="blue darken-2" small @click="viewPAR(transferred)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon color="red darken-2" small @click="printPAR(transferred)">
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                </template>
                <template v-slot:[`item.ReturnQty`]="{ item }">
                  {{formatQty(item.ReturnQty)}}
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="150"
                    offset-x
                  >
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" v-if="item.ReturnQty > 0" icon absolute color="blue darken-2" class="mt-n4" small>
                      <v-icon>mdi-help-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar color="blue darken-2">
                          <v-icon large color="white">
                            mdi-clipboard-arrow-left
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="blue--text darken-2 font-weight-bold">Returned</v-list-item-title>
                          <v-list-item-subtitle>Number of RS: <b>{{item.returned_par.length}}</b></v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action>
                          <v-btn
                            icon
                            @click="menu = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item v-for="(returned, index) in item.returned_par" :key="index">
                        <v-list-item-title>{{returned.RSCODE}}</v-list-item-title>
                        <v-list-item-action class="mr-1">
                          <span class="text-no-wrap">
                          <v-btn icon color="blue darken-2" small @click="viewRS(returned)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn icon color="red darken-2" small @click="printRS(returned)">
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                    <v-row v-for="(mr, index) in item.MRData" :key="index">
                      <v-col cols="12" class="px-2">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <strong>Persons Accountable: </strong>
                          </v-col>
                          <v-col cols="12" sm="9">
                            {{getPANames(mr.UserID)}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                         <strong>Brand: </strong> {{mr.Brand || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Model: </strong> {{mr.Model || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Serial No.: </strong> {{mr.SerialNo || 'Not applicable'}}
                      </v-col>
                      <v-col v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialogProcess" max-width="1000px" scrollable>
        <v-card>
          <v-card-title class="headline pt-5" v-if="process_type == 1">PAR Renewal</v-card-title>
          <v-card-title class="headline pt-5" v-if="process_type == 2">PAR Transfer</v-card-title>
          <v-card-title class="headline pt-5" v-if="process_type == 3">PAR Return</v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="RTRFormRef">
          <v-container>
            <v-row wrap no-gutters align="center" v-if="process_type != 3">
              <v-col cols="12" md="12">
                  <v-autocomplete
                      v-model="DispenseTypeID"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="dispenseTypes"
                      :disabled="process_type == 1"
                      color="header"
                      dense
                      outlined
                      label="Dispense Type">
                      </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                  <v-autocomplete
                           v-model="formData.UserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            :disabled="process_type == 1"
                            dense
                            outlined
                            v-if="DispenseTypeID == 1"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="formData.UserID"
                        label="Persons Accountable"
                        :rules="[formRules.required]"
                        :disabled="process_type == 1"
                        outlined
                        v-if="DispenseTypeID == 2"
                        color="header"
                        :items="users"
                        item-text="name"
                        item-value="UserID"
                        small-chips
                        deletable-chips
                        multiple
                      ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                <v-textarea
                  dense
                  outlined
                  color="header"
                  v-model="formData.PARPurpose"
                  :rules="[formRules.required]"
                  label="PAR Purpose"
                ></v-textarea>
                </v-col>
            </v-row>
            <v-row wrap align="center" v-else>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="formData.Returner"
                    label="Returned By"
                    :rules="[formRules.required]"
                    outlined
                    dense
                    color="header"
                    :items="returners"
                    item-text="name"
                    item-value="UserID"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateReturned"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateReturned"
                        :rules="[formRules.required]"
                        label="Date Returned"
                        readonly
                        outlined
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateReturned"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="header" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="header"
                        @click="$refs.menu1.save(formData.DateReturned)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                <v-textarea
                  dense
                  outlined
                  color="header"
                  v-model="formData.Remarks"
                  label="Remarks "
                ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
              <v-data-table
                :headers="headers2"
                :items="formData.Lists"
                class="elevation-1"
                :expanded.sync="expanded2"
                :show-expand="false"
              >
              <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2">List Of Selected Items</span>
                  </v-toolbar>
              </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.allowableQty`]="{ item }">
                  {{ formatQty(item.allowableQty) }}
                </template>
                <template v-slot:[`item.editableQty`]="{ item }">
                  <v-edit-dialog :return-value.sync="item.editableQty" large>
                    <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                    <template v-slot:input>
                      <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.ReturnType`]="{ item }">
                  <v-autocomplete
                      dense
                      outlined
                      hide-details
                      v-model="item.ReturnTypeID"
                      :rules="[formRules.required]"
                      color="header"
                      item-text="description"
                      auto-select-first
                      item-value="id"
                      :items="returnTypes"
                      v-if="process_type == 3"
                      >
                  </v-autocomplete>
                  <span v-else>Serviceable</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                      <v-autocomplete
                        v-model="item.MRData_selected"
                        label="Brand | Model | Serial No."
                        @change="changeSerialNo(item)"
                        :rules="[formRules.required, formRules.checkMRBalance(item.MRData_selected.length, item.editableQty)]"
                        outlined
                        dense
                        chips
                        hide-selected
                        :menu-props="{closeOnContentClick: true}"
                        :counter="item.editableQty" 
                        auto-select-first
                        small-chips
                        deletable-chips
                        multiple
                        color="header"
                        :items="item.MRData"
                        :item-text="getItemText"
                        :item-value="getItemValue"
                      ></v-autocomplete>
                    </div>
                  </td>
                </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialogProcess = false">Cancel</v-btn>
            <v-btn v-if="process_type == 1" color="header darken-2" :loading="rtrLoading" class="white--text" @click="confirmRenew()"><v-icon>mdi-autorenew</v-icon> Renew</v-btn>
            <v-btn v-if="process_type == 2" color="amber darken-2" :loading="rtrLoading" class="white--text" @click="confirmTransfer()"><v-icon>mdi-transfer</v-icon> Transfer</v-btn>
            <v-btn v-if="process_type == 3" color="blue darken-2" :loading="rtrLoading" class="white--text" @click="confirmReturn()"><v-icon>mdi-clipboard-arrow-left</v-icon> Return</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <PARModal :parData="parData2" />
    <RSModal :rsData="rsData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  components:{
    PARModal: () => import("./ViewPAR.vue"),
    RSModal: () => import("./ViewRS.vue"),
  },
  props: {
    parData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    users_temp: [],
    dialogModel: false,
    formData: {
      Lists: [],
      PARPurpose: '',
      UserID: null,
      ReturnTypeID: null,
      DateReturned: null,
      Returner: null,
      Remarks: '',
    },
    itemList: [],
    disabledCount: 0,
    isLoading: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
      { text: "Qty(Renewed)", align:"center", value: "RenewQty", sortable: false },
      { text: "Qty(Transferred)", align:"center", value: "TransferQty", sortable: false },
      { text: "Qty(Returned)", align:"center", value: "ReturnQty", sortable: false },
    ],
    expanded: [],
    expanded2: [],
    singleExpand: true,
    dialogProcess: false,
    rtrLoading: false,
    dispenseTypes: [],
    DispenseTypeID: null,
    process_type: null,
    UserID_temp: null,
    headers2: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Qty(Overall)", align:"center", value: "Qty", sortable: false },
      { text: "Qty(Allowable)", align:"center", value: "allowableQty", sortable: false },
      { text: "Qty(Editable)", align:"center", value: "editableQty", sortable: false },
      { text: "Status", align:"center", value: "ReturnType", sortable: false },
    ],
    parData2: [],
    rsData: [],
    menu1: false,
    returners: [],
    renewalData: null,
    DateStart_renewal: '',
    DateEnd_renewal: '',
    returnTypes: [],
  }),
  watch: {
    parData: {
      handler(data) {
        if (data.PARID) {
          this.DispenseTypeID = data.DispenseTypeID;
          this.formData.PARPurpose = data.PARPurpose;
          if(data.DispenseTypeID == 1){ 
            this.formData.UserID = parseInt(data.UserID);
            this.getReturners([data.UserID]);
          }
          else if(data.DispenseTypeID == 2){
            let d = data.UserID.split(',');
            this.getReturners(d);
            this.formData.UserID = d.map(function (x) { 
              return parseInt(x, 10); 
            });
          }
          this.UserID_temp = this.formData.UserID;
          this.expanded = [];
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
    DispenseTypeID: {
      handler() {

      },
      deep: true,
    },
    process_type: {
      handler(data) {
        this.users = JSON.parse(JSON.stringify(this.users_temp))
        if(data == 2){
          if(this.DispenseTypeID == 1){ 
            this.users.forEach(item => {
                if(this.formData.UserID == item.UserID){
                  item.disabled = true
                }
            });
          }
          else if(this.DispenseTypeID == 2){
            this.formData.UserID.forEach(item1 => {
              this.users.forEach(item2 => {
                if(item1 == item2.UserID){
                  item2.disabled = true
                }
            });
            });
          }
          this.formData.UserID = null;
        }
        else{
          this.formData.UserID = JSON.parse(JSON.stringify(this.UserID_temp))
        }
      },
      deep: true,
    },
  },
  created() {
    const self = this;
    this.itemList.map(item => {
      if (item.disabled) self.disabledCount += 1
    })
  },
  mounted() {
    this.getDispenseTypes();
    this.getReturnTypes();
    this.checkRenewal();
    this.eventHub.$on("closeViewPAR");
    this.eventHub.$on("closeViewRS");
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewPAR");
    this.eventHub.$off("closeViewRS");
  },
  methods: {
    clickCloseMainDialog() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeProcessPAR", false);
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      this.isLoading = true;
      data.append("PARID", this.parData.PARID);
      this.axiosCall("/par/getItems", "POST", data).then((res) => {
        res.data.data.result.forEach(item => {
          item.allowableQty = parseFloat(item.Qty) - (parseFloat(item.RenewQty) + parseFloat(item.TransferQty)  + parseFloat(item.ReturnQty));
          item.editableQty = item.allowableQty;
          item.ReturnType = true;
          if(item.disabled == 1){
            item.disabled = true
          }
          else{
            item.disabled = false
          }
        });
        this.itemList = res.data.data.result;
        this.getMRData();
        this.users = res.data.data.users;
        this.users_temp = res.data.data.users;
        this.isLoading = false;
      });
    },
    async getMRData(){
      await this.itemList.forEach(item => {
        let data = new FormData();
        data.append("PARItemID", item.PARItemID);
        this.axiosCall("/mritem/par/getItems", "POST", data).then((res) => {
          item.MRData = [];
          if(res.status){
            item.MRData = res.data.data;
            let temp = [];
            item.MRData.forEach(item2 => {
              if(item2.rsdata == null){
                temp.push(item2);
              }
            });
              item.MRData_selected = temp;
          }
      });
      });
      this.selectAllToggle({items: this.itemList, value: true})
      this.selectAllToggle({items: this.itemList, value: false})
    },
    getPANames(data){
      let names = data.split(',');
      let result = [];
      names.forEach(item1 => {
        this.users.forEach(item2 => {
          if(item1 == item2.UserID){
            result.push(item2.name)
          }
      });
      });
      return result.join(', ')
    },
    renew(){
      this.process_type = 1
      this.dialogProcess = true;
    },
    transfer(){
      this.process_type = 2
      this.dialogProcess = true;
    },
    returnPAR(){
      this.process_type = 3
      this.dialogProcess = true;
    },
    confirmRenew(){
      if(this.$refs.RTRFormRef.validate() && this.formData.Lists.length > 0){
        this.rtrLoading = true;
        this.formData.Lists.forEach(item => {
          let mritem = [];
          item.MRData_selected.forEach(item2 => {
            mritem.push(item2.MRItemID);
          });
          item.MRData_selected = mritem;
        });
        let data = new FormData();
        data.append("PARID", this.parData.PARID);
        data.append("PARPurpose", this.formData.PARPurpose);
        data.append("DispenseTypeID", this.DispenseTypeID);
        data.append("UserID", this.formData.UserID);
        data.append("List", JSON.stringify(this.formData.Lists));
        this.axiosCall("/par/renew", "POST", data).then((res) => {
          if(res.data.status){
            window.open(this.apiUrl + "/par/report/" + res.data.data);
            this.clickCloseMainDialog();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
          this.rtrLoading = false;
          });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    confirmTransfer(){
      if(this.$refs.RTRFormRef.validate() && this.formData.Lists.length > 0){
        this.rtrLoading = true;
        this.formData.Lists.forEach(item => {
          let mritem = [];
          item.MRData_selected.forEach(item2 => {
            mritem.push(item2.MRItemID);
          });
          item.MRData_selected = mritem;
        });
        let data = new FormData();
        data.append("PARID", this.parData.PARID);
        data.append("PARPurpose", this.formData.PARPurpose);
        data.append("DispenseTypeID", this.DispenseTypeID);
        data.append("UserID", this.formData.UserID);
        data.append("List", JSON.stringify(this.formData.Lists));
        this.axiosCall("/par/transfer", "POST", data).then((res) => {
          if(res.data.status){
            window.open(this.apiUrl + "/par/report/" + res.data.data);
            this.clickCloseMainDialog();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
          this.rtrLoading = false;
          });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    confirmReturn(){
      if(this.$refs.RTRFormRef.validate() && this.formData.Lists.length > 0){
        this.rtrLoading = true;
        this.formData.Lists.forEach(item => {
          let mritem = [];
          item.MRData_selected.forEach(item2 => {
            mritem.push(item2.MRItemID);
          });
          item.MRData_selected = mritem;
        });
        let data = new FormData();
        data.append("IARID", this.parData.IARID ? this.parData.IARID : '');
        data.append("RISID", this.parData.RISID ? this.parData.RISID : '');
        data.append("ICSPARID", this.parData.PARID);
        data.append("ICSPARType", 2);
        data.append("UserID", this.formData.Returner);
        data.append("DateReturned", this.formData.DateReturned);
        data.append("Remarks", this.formData.Remarks ? this.formData.Remarks : '');
        data.append("List", JSON.stringify(this.formData.Lists));
        this.axiosCall("/rs/add", "POST", data).then((res) => {
          if(res.data.status){
            window.open(this.apiUrl + "/rs/report/" + res.data.data);
            this.clickCloseMainDialog();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
          this.rtrLoading = false;
          });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    getDispenseTypes() {
      this.axiosCall("/getAllDispenseType", "GET").then((res) => {
        this.dispenseTypes = res.data.data;
      });
    },
    getReturnTypes() {
      this.axiosCall("/getAllReturnType", "GET").then((res) => {
        this.returnTypes = res.data.data;
      });
    },
    getReturners(users) {
      let data = new FormData();
        data.append("Users", JSON.stringify(users));
      this.axiosCall("/getReturners", "POST", data).then((res) => {
        this.returners = res.data.data;
        this.formData.Returner = this.returners[0].UserID
      });
    },
    selectAllToggle(props) {
       if(this.formData.Lists.length != this.itemList.length - this.disabledCount) {
         this.formData.Lists = [];
         const self = this;
         props.items.forEach(item => {
           if(!item.disabled) {
            let temp = [];
            item.MRData.forEach(item2 => {
              if(item2.rsdata == null){
                temp.push(item2);
              }
            });
              item.MRData_selected = temp;
             self.formData.Lists.push(item);
             self.expanded2.push(item)
           } 
         });
       } else this.formData.Lists = [];
     },
    viewPAR(item) {
      this.parData2 = item;
    },
    printPAR(item) {
      let link = this.apiUrl + "/par/report/" + item.PARID;
      window.open(link);
    },
    viewRS(item) {
      this.rsData = item;
    },
    printRS(item) {
      let link = this.apiUrl + "/rs/report/" + item.RSID;
      window.open(link);
    },
    checkRenewal(){
    this.renewalData = null;
          this.axiosCall(
          '/getRenewalDate',
          'POST',
          []
      )
      .then((res) => {
        if(res.data.data.result){
          this.renewalData = res.data.data.result;
          const DateStart_renewal = new Date(res.data.data.result.DateStart);
          const DateEnd_renewal = new Date(res.data.data.result.DateEnd);
          const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
          const day = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
          this.DateStart_renewal = month[DateStart_renewal.getMonth()] + ' ' + DateStart_renewal.getDate() + ', ' + DateStart_renewal.getFullYear() + ' (' + day[DateStart_renewal.getDay()] + ')';
          this.DateEnd_renewal = month[DateEnd_renewal.getMonth()] + ' ' + DateEnd_renewal.getDate() + ', ' + DateEnd_renewal.getFullYear() + ' (' + day[DateEnd_renewal.getDay()] + ')';
        }
      })
    },
    getItemText(item) {
      return `${item.Brand ? item.Brand : 'No Brand'}` + ' | ' + `${item.Model ? item.Model : 'No Model'}` + ' | ' + `${item.SerialNo ? item.SerialNo : 'No Serial No.'}`;
    },
    getItemValue(item) {
      return item;
    },
    changeSerialNo(item){
      let temp = item.editableQty;
      item.editableQty = 0;
      item.editableQty = temp;
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>